import Paper, { PaperProps } from "@mui/material/Paper";
import { forwardRef } from "react";

const ImageContainer = forwardRef<HTMLDivElement, PaperProps>(
  ({ sx, ...props }, ref) => {
    return (
      <Paper
        ref={ref}
        sx={{
          width: "100%",
          borderRadius: 1,
          overflow: "hidden",
          backgroundImage: "none",
          display: "flex",
          m: 2,
          ...sx,
        }}
        elevation={5}
        {...props}
      />
    );
  }
);

export default ImageContainer;
