import {
  AuthChangeEvent,
  AuthError,
  Session,
  SignInWithOAuthCredentials,
  SignInWithPasswordCredentials,
  SignInWithPasswordlessCredentials,
  User,
} from "@supabase/supabase-js";
import { StateCreator } from "zustand";
import { v4 as uuid } from "uuid";

import { supabase } from "../../supabase/supabaseClient";
import { SupabaseError } from "@obr/features/supabase/SupabaseError";

export interface AuthSlice {
  user: User | null;
  role: "ANON" | "AUTHENTICATED" | "AUTHENTICATED_ANON";
  session: Session | null;
  signInWithOAuth: (payload: SignInWithOAuthCredentials) => Promise<void>;
  signInWithPassword: (payload: SignInWithPasswordCredentials) => Promise<void>;
  signInWithOTP: (payload: SignInWithPasswordlessCredentials) => Promise<void>;
  signOut: () => Promise<{ error: AuthError | null }>;
  verifyOTP: (token: string, email: string) => Promise<boolean>;
  createAnonUser: () => Promise<User>;
  updateEmail: (email: string) => Promise<void>;
  updatePassword: (oldPassword: string, newPassword: string) => Promise<void>;
  createPassword: (password: string) => Promise<void>;
  resetPassword: (email: string) => Promise<void>;
  loading: boolean;
  /** Prevent the auth status from redirecting */
  shouldRedirect: boolean;
  setShouldRedirect: (redirect: boolean) => void;
  setAuth: (accessToken: string, refreshToken: string) => Promise<void>;
  refreshSession: () => Promise<void>;
  recovering: boolean;
  getSession: () => Promise<Session | null>;
}

export const authSlice: StateCreator<AuthSlice> = (set, get) => {
  supabase.auth.onAuthStateChange((status: AuthChangeEvent, session) => {
    set((state) => {
      const user = session?.user || null;
      const role =
        user === null
          ? "ANON"
          : user.email && user.email.endsWith("@owlbear.app")
          ? "AUTHENTICATED_ANON"
          : "AUTHENTICATED";
      state.user = user;
      state.role = role;
      state.session = session;
      if (status === "PASSWORD_RECOVERY") {
        state.recovering = true;
      }
    });
  });

  supabase.auth.initialize().finally(() => {
    set((state) => {
      state.loading = false;
    });
  });

  return {
    user: null,
    session: null,
    role: "ANON",
    signInWithPassword: async (payload) => {
      const { error } = await supabase.auth.signInWithPassword(payload);

      if (error) {
        throw new SupabaseError(error);
      }
    },
    signInWithOAuth: async (payload) => {
      const { error } = await supabase.auth.signInWithOAuth(payload);

      if (error) {
        throw new SupabaseError(error);
      }
    },
    signInWithOTP: async (payload) => {
      const { error } = await supabase.auth.signInWithOtp(payload);

      if (error) {
        throw new SupabaseError(error);
      }
    },
    signOut: async () => {
      return supabase.auth.signOut({ scope: "local" });
    },
    createAnonUser: async () => {
      const { data, error } = await supabase.auth.signUp({
        email: `${uuid()}@owlbear.app`,
        password: uuid(),
      });

      if (error) {
        throw new SupabaseError(error);
      }

      if (!data.user) {
        throw Error("Unable to create anonymous user");
      }

      set((state) => {
        state.user = data.user;
        state.role = "AUTHENTICATED_ANON";
        state.session = data.session;
      });

      return data.user;
    },
    verifyOTP: async (token, email) => {
      let success = false;

      const { error, data } = await supabase.auth.verifyOtp({
        token,
        email,
        type: "magiclink",
      });
      if (error) {
        throw new SupabaseError(error);
      }
      if (data.user) {
        set((state) => {
          state.user = data.user;
          state.role = "AUTHENTICATED";
          state.session = data.session;
        });
        success = true;
      }

      return success;
    },
    updateEmail: async (email) => {
      const { error } = await supabase.auth.updateUser({ email });
      if (error) {
        throw new SupabaseError(error);
      }
    },
    createPassword: async (password) => {
      const session = get().session;
      if (!session) {
        throw Error("Unable to update password: invalid session");
      }
      const { error } = await supabase.auth.updateUser({ password });
      if (error) {
        throw new SupabaseError(error);
      }

      const response = await fetch(
        `${import.meta.env.VITE_OWLBEAR_API}/api/stripe/user-profile`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: session.access_token,
          },
          body: JSON.stringify({ isPasswordCreated: true }),
        }
      );

      if (!response.ok) {
        throw Error("Unable to create password: failed to update profile");
      }

      // Disable the recover password state
      set((state) => {
        if (state.recovering) {
          state.recovering = false;
        }
      });
    },
    updatePassword: async (oldPassword, newPassword) => {
      const user = get().user;
      if (!user || !user.email) {
        throw Error("Unable to find user email");
      }
      const { error: signInError } = await supabase.auth.signInWithPassword({
        email: user.email,
        password: oldPassword,
      });
      if (signInError) {
        throw new SupabaseError(signInError);
      }
      const { error } = await supabase.auth.updateUser({
        password: newPassword,
      });
      if (error) {
        throw new SupabaseError(error);
      }
    },
    resetPassword: async (email) => {
      const url = window.location.origin;
      const { error } = await supabase.auth.resetPasswordForEmail(email, {
        redirectTo: `${url}/recover-password`,
      });
      if (error) {
        throw new SupabaseError(error);
      }
    },
    loading: true,
    shouldRedirect: true,
    setShouldRedirect: (redirect) => {
      set((state) => {
        state.shouldRedirect = redirect;
      });
    },
    setAuth: async (accessToken, refreshToken) => {
      const { error } = await supabase.auth.setSession({
        access_token: accessToken,
        refresh_token: refreshToken,
      });
      if (error) {
        throw new SupabaseError(error);
      }
    },
    refreshSession: async () => {
      await supabase.auth.refreshSession();
    },
    recovering: false,
    getSession: async () => {
      const { data, error } = await supabase.auth.getSession();
      if (error) {
        throw new SupabaseError(error);
      }
      return data.session;
    },
  };
};
