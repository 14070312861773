import { immer } from "@obr/common/store/middleware/immer";
import embeddableStorage from "@obr/features/embed/embeddableStorage";
import create from "zustand";
import { persist } from "zustand/middleware";

import { whatsNewModalSlice, WhatsNewModalSlice } from "./whatsNewModalSlice";
import { whatsNewSlice, WhatsNewSlice } from "./whatsNewSlice";

export type WhatsNewState = WhatsNewSlice;

export const useWhatsNewStore = create<WhatsNewState>(
  persist(
    immer((set, get, api) => ({
      ...whatsNewSlice(set, get, api),
    })),
    {
      name: "persist:whats-new",
      getStorage: () => embeddableStorage,
    }
  )
);

export type WhatsNewModalState = WhatsNewModalSlice;

export const useWhatsNewModalStore = create<WhatsNewModalState>(
  immer((set, get, api) => ({
    ...whatsNewModalSlice(set, get, api),
  }))
);
