import React, { useEffect } from "react";

function usePreventZoom(elementRef: React.RefObject<HTMLElement>) {
  useEffect(() => {
    // Stop pinch to zoom on desktop
    function preventWheelZoom(event: WheelEvent) {
      if (event.ctrlKey) {
        event.preventDefault();
      }
    }
    // Stop touch zoom
    function preventTouchZoom(event: TouchEvent) {
      if (event.touches.length > 1) {
        event.preventDefault();
      }
    }
    const element = elementRef.current;
    if (element) {
      element.addEventListener("wheel", preventWheelZoom, {
        passive: false,
      });
      element.addEventListener("touchstart", preventTouchZoom, {
        passive: false,
      });
    }

    return () => {
      if (element) {
        element.removeEventListener("wheel", preventWheelZoom);
        element.removeEventListener("touchstart", preventTouchZoom);
      }
    };
  }, [elementRef]);
}

export default usePreventZoom;
