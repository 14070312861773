import { StateCreator } from "zustand";

export interface WhatsNewSlice {
  lastSeenAppVersion?: string;
  markSeen: (appVersion: string) => void;
}

export const whatsNewSlice: StateCreator<WhatsNewSlice> = (set) => ({
  lastSeenAppVersion: APP_VERSION,
  markSeen: (appVersion) => {
    set((state) => {
      state.lastSeenAppVersion = appVersion;
    });
  },
});
