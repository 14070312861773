import hotkeys from "hotkeys-js";
import { useEffect } from "react";
import { useShortcutsStore } from "./store";

function useShortcutScope(scope: string) {
  const addScope = useShortcutsStore((state) => state.addScope);
  const removeScope = useShortcutsStore((state) => state.removeScope);
  useEffect(() => {
    hotkeys.setScope(addScope(scope));

    return () => {
      hotkeys.setScope(removeScope(scope));
    };
  }, [scope, addScope, removeScope]);
}

export default useShortcutScope;
