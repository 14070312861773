export const isMacLike = /(Mac|iPhone|iPod|iPad)/i.test(navigator.platform);

type Shortcut = {
  tooltip: string;
  key: string;
};

export const commonShortcuts = {
  copy: {
    tooltip: isMacLike ? "⌘ + C" : "Ctrl + C",
    key: isMacLike ? "⌘ + C" : "Ctrl + C",
  },
  paste: {
    tooltip: isMacLike ? "⌘ + V" : "Ctrl + V",
    key: isMacLike ? "⌘ + V" : "Ctrl + V",
  },
  duplicate: {
    tooltip: isMacLike ? "⌘ + D" : "Ctrl + D",
    key: isMacLike ? "⌘ + D" : "Ctrl + D",
  },
  delete: {
    tooltip: "",
    key: "backspace, delete",
  },
  undo: {
    tooltip: isMacLike ? "⌘ + Z" : "Ctrl + Z",
    key: isMacLike ? "⌘ + Z" : "Ctrl + Z",
  },
  redo: {
    tooltip: isMacLike ? "⌘ + Shift + Z" : "Ctrl + Shift + Z",
    key: isMacLike ? "⌘ + Shift + Z" : "Ctrl + Shift + Z",
  },
  search: {
    tooltip: isMacLike ? "⌘ + K" : "Ctrl + K",
    key: isMacLike ? "⌘ + K" : "Ctrl + K",
  },
  assetManager: {
    tooltip: isMacLike ? "⌘ + I" : "Ctrl + I",
    key: isMacLike ? "⌘ + I" : "Ctrl + I",
  },
  grid: {
    tooltip: isMacLike ? "⌘ + G" : "Ctrl + G",
    key: isMacLike ? "⌘ + G" : "Ctrl + G",
  },
  syncView: {
    tooltip: isMacLike ? "⌘ + Ctrl + S" : "Ctrl + Alt + S",
    key: isMacLike ? "⌘ + Ctrl + S" : "Ctrl + Alt + S",
  },
  syncViewOnce: {
    tooltip: isMacLike ? "⌘ + Shift + S" : "Ctrl + Shift + S",
    key: isMacLike ? "⌘ + Shift + S" : "Ctrl + Shift + S",
  },
  resetView: {
    tooltip: isMacLike ? "⌘ + Ctrl + R" : "Ctrl + Alt + R",
    key: isMacLike ? "⌘ + Ctrl + R" : "Ctrl + Alt + R",
  },
  editText: {
    tooltip: "Shift + Enter",
    key: "Shift + Enter",
  },
  extras: {
    tooltip: isMacLike ? "⌘ + E" : "Ctrl + E",
    key: isMacLike ? "⌘ + E" : "Ctrl + E",
  },
  hide: {
    tooltip: "H",
    key: "H",
  },
  lock: {
    tooltip: "L",
    key: "L",
  },
  attach: {
    tooltip: "A",
    key: "A",
  },
  transform: {
    tooltip: "Z",
    key: "Z",
  },
  selectAll: {
    tooltip: isMacLike ? "⌘ + A" : "Ctrl + A",
    key: isMacLike ? "⌘ + A" : "Ctrl + A",
  },
  selectAllLocked: {
    tooltip: isMacLike ? "⌘ + Shift + A" : "Ctrl + Shift + A",
    key: isMacLike ? "⌘ + Shift + A" : "Ctrl + Shift + A",
  },
  moveViewport: {
    tooltip: "Move Viewport",
    key: "space",
  },
  moveLeft: {
    tooltip: "left",
    key: "left",
  },
  moveUp: {
    tooltip: "up",
    key: "up",
  },
  moveDown: {
    tooltip: "down",
    key: "down",
  },
  moveRight: {
    tooltip: "right",
    key: "right",
  },
  manualEditorControls: {
    tooltip: isMacLike ? "⌘ + E" : "Ctrl + E",
    key: isMacLike ? "⌘ + E" : "Ctrl + E",
  },
  zoomIn: {
    tooltip: "zoom in",
    key: "=, shift + =, num_add",
  },
  zoomOut: {
    tooltip: "zoom out",
    key: "-, shift + -, num_subtract",
  },
  layer: {
    tooltip: isMacLike ? "⌘ + L" : "Ctrl + L",
    key: isMacLike ? "⌘ + L" : "Ctrl + L",
  },
  replaceImage: {
    tooltip: isMacLike ? "⌘ + I" : "Ctrl + I",
    key: isMacLike ? "⌘ + I" : "Ctrl + I",
  },
  colorMode: {
    tooltip: isMacLike ? "⌘ + Ctrl + L" : "Ctrl + Shift + L",
    key: isMacLike ? "⌘ + Ctrl + L" : "Ctrl + Shift + L",
  },
  hideUI: {
    tooltip: isMacLike ? "⌘ + Ctrl + H" : "Ctrl + Shift + H",
    key: isMacLike ? "⌘ + Ctrl + H" : "Ctrl + Shift + H",
  },
};

export type CommonShortcuts = typeof commonShortcuts;

export type CommonShortcutKey = keyof CommonShortcuts;

export function isCommonShortcut(
  shortcut: string
): shortcut is CommonShortcutKey {
  return shortcut in commonShortcuts;
}

export function getShortcutFromKey(key: string): Shortcut {
  if (isCommonShortcut(key)) {
    return commonShortcuts[key];
  } else {
    return {
      tooltip: key,
      key: key,
    };
  }
}

export default commonShortcuts;
