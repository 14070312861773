import { createSvgIcon } from "@mui/material/utils";

const OwlbearIcon = createSvgIcon(
  <g>
    <path d="m20.777 10.973.04.032.042.034.041.033c.378.305.735.615.735.928 0 5.247-1.844 9.5-9.517 9.5-7.673 0-9.517-4.253-9.517-9.5 0-.345.418-.685.83-1.02L12.277 12l8.5-1.027Zm-6.814 6.698h-3.837l1.918 2.798 1.919-2.798Zm-6.228-4.467a2.185 2.185 0 0 0-2.187 2.184c0 1.205.98 2.183 2.187 2.183a2.185 2.185 0 0 0 2.187-2.183c0-1.206-.979-2.184-2.187-2.184Zm8.84 0a2.185 2.185 0 0 0-2.187 2.184c0 1.205.98 2.183 2.187 2.183a2.185 2.185 0 0 0 2.187-2.183c0-1.206-.979-2.184-2.187-2.184ZM12.118 2.5c4.025 0 6.388 2.493 7.503 6.017-4.199.552-6.652.828-7.36.828-.705 0-3.254-.275-7.644-.825C5.735 4.995 8.093 2.5 12.118 2.5Z" />
    <path d="m7.979 14.915 3.7.236-.793 2.062-2.907-2.298Zm8.801 0 3.701.236-.793 2.062-2.908-2.298ZM1.32 9.277c6.523.832 10.17 1.248 10.943 1.248.77 0 4.243-.413 10.42-1.24A1.163 1.163 0 0 1 24 10.437c0 .758-.567 1.396-1.32 1.487L12.278 13.18 1.32 11.916A1.49 1.49 0 0 1 0 10.436a1.17 1.17 0 0 1 1.319-1.159Z" />
  </g>,
  "Owlbear"
);

export default OwlbearIcon;
