import { immer } from "@obr/common/store/middleware/immer";
import embeddableStorage from "@obr/features/embed/embeddableStorage";
import create from "zustand";
import { persist } from "zustand/middleware";
import { themeSlice, ThemeSlice } from "./themeSlice";

export type ThemeState = ThemeSlice;

export const useThemeStore = create<ThemeState>(
  persist(
    immer((set, get, api) => ({
      ...themeSlice(set, get, api),
    })),
    { name: "persist:theme", getStorage: () => embeddableStorage }
  )
);
