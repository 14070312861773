import { useAuthStore } from "@obr/features/auth/store";
import * as Sentry from "@sentry/react";
import { Extras } from "@sentry/types";

let initialized = false;

export function captureException(error: Error, extra?: Extras) {
  if (!initialized) {
    Sentry.init({
      dsn: import.meta.env.VITE_SENTRY_DSN,
      integrations: [
        new Sentry.Integrations.GlobalHandlers({
          onerror: false,
          onunhandledrejection: false,
        }),
      ],
    });
    initialized = true;
  }
  Sentry.captureException(error, {
    user: { id: useAuthStore.getState().user?.id },
    extra,
  });
}
