import Button from "@mui/material/Button";
import Card from "@mui/material/Card";
import Link from "@mui/material/Link";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";

import LazyMedia from "@obr/common/LazyMedia";

import ImageContainer from "../../ImageContainer";

import hero from "./hero.jpg";

function WhatsNew200() {
  return (
    <Card variant="outlined">
      <Stack alignItems="center" p={2}>
        <Typography variant="h5">Owlbear Rodeo 2.0</Typography>
        <Typography variant="caption" color="text.secondary">
          July 2023
        </Typography>
        <ImageContainer>
          <LazyMedia
            image={{ src: hero, width: 1920, height: 980 }}
            alt="owlbear rodeo 2.0 exports modal"
          />
        </ImageContainer>
        <Typography variant="body1">
          Owlbear Rodeo 2.0 has officially launched 🎉
        </Typography>
        <Typography variant="body1">
          If you're coming from 1.0 learn about all the changes{" "}
          <Link
            href="https://docs.owlbear.rodeo/docs/migration/migrate-from-1-to-2/"
            rel="noopener noreferrer"
            target="_blank"
          >
            here
          </Link>
          .
        </Typography>
        <Button
          href="https://blog.owlbear.rodeo/owlbear-rodeo-2-0-launch-patch/"
          rel="noopener noreferrer"
          target="_blank"
          fullWidth
          sx={{ mt: 2 }}
        >
          See more
        </Button>
      </Stack>
    </Card>
  );
}

export default WhatsNew200;
