import hotkeys from "hotkeys-js";
import { StateCreator } from "zustand";

import shortcuts from "@obr/features/shortcuts";

const isFirefox = navigator.userAgent.toLowerCase().indexOf("firefox") > -1;

export type OverlayEffect = "GLASS" | "TRANSPARENT";
export type Mode = "DARK" | "LIGHT";

export interface ThemeSlice {
  mode: Mode;
  setMode: (mode: Mode) => void;
  toggleMode: () => void;
  overlayEffect: OverlayEffect;
  setOverlayEffect: (effect: OverlayEffect) => void;
}

export const themeSlice: StateCreator<ThemeSlice> = (set) => {
  hotkeys(shortcuts.colorMode.key, () => {
    set((state) => {
      state.mode = state.mode === "DARK" ? "LIGHT" : "DARK";
    });
  });

  return {
    mode: "DARK",
    setMode: (mode) => {
      set((state) => {
        state.mode = mode;
      });
    },
    toggleMode: () => {
      set((state) => {
        state.mode = state.mode === "DARK" ? "LIGHT" : "DARK";
      });
    },
    // Disable blur on firefox because it is too slow
    overlayEffect: isFirefox ? "TRANSPARENT" : "GLASS",
    setOverlayEffect: (effect) => {
      set((state) => {
        state.overlayEffect = effect;
      });
    },
  };
};
