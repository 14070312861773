import { useState, FormEvent } from "react";

import Alert from "@mui/material/Alert";
import Box from "@mui/material/Box";
import CheckIcon from "@mui/icons-material/CheckRounded";
import LoadingButton from "@mui/lab/LoadingButton";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";

import Stack from "@mui/material/Stack";
import CircularProgress from "@mui/material/CircularProgress";

import { useNotificationsStore } from "@obr/features/notifications/store";
import { useAuthStore } from "./store";

type ResetPasswordFormProps = {
  onSuccess: () => void;
};

const formContainerSx = { mt: 1, width: "100%" };

function ResetPasswordForm({ onSuccess }: ResetPasswordFormProps) {
  const showNotification = useNotificationsStore(
    (state) => state.showNotification
  );

  const [email, setEmail] = useState("");
  const [isSuccessfullySubmitted, setIsSuccessfullySubmitted] =
    useState<boolean>(false);
  const [loading, setLoading] = useState(false);
  const [disable, setDisable] = useState(false);

  const resetPassword = useAuthStore((state) => state.resetPassword);

  async function handlePasswordRecovery(e: FormEvent<HTMLFormElement>) {
    e.preventDefault();
    try {
      setLoading(true);
      setDisable(true);

      await resetPassword(email);

      setIsSuccessfullySubmitted(true);
      onSuccess();
    } catch (error) {
      console.error(error);
      if (error instanceof Error) {
        showNotification(error.message, { variant: "error" });
      } else {
        showNotification("Unknown error occurred", { variant: "error" });
      }
    } finally {
      setLoading(false);
    }
  }

  const userLoading = useAuthStore((state) => state.loading);

  if (userLoading) {
    return (
      <Stack alignItems="center" m={3}>
        <CircularProgress />
      </Stack>
    );
  }

  return (
    <>
      <Typography component="h1" variant="h5">
        Forgot Password?
      </Typography>
      <Box
        component="form"
        onSubmit={handlePasswordRecovery}
        sx={formContainerSx}
      >
        {isSuccessfullySubmitted && (
          <Alert icon={<CheckIcon fontSize="inherit" />} severity="success">
            Success — check your email for further instructions
          </Alert>
        )}
        <TextField
          margin="normal"
          required
          fullWidth
          id="email"
          label="Enter account email address"
          name="email"
          autoComplete="email"
          size="small"
          autoFocus
          onChange={(e) => setEmail(e.target.value)}
          disabled={disable}
        />
        <LoadingButton
          type="submit"
          fullWidth
          variant="contained"
          sx={{ mt: 1, mb: 2 }}
          loading={loading}
          disabled={disable}
        >
          <div>Submit</div>
        </LoadingButton>
      </Box>
    </>
  );
}

export default ResetPasswordForm;
