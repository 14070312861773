import MuiThemeProvider from "@mui/material/styles/ThemeProvider";

import { getLightThemeWithOverlayEffect } from "./light";
import { getDarkThemeWithOverlayEffect } from "./dark";
import { useThemeStore } from "./store";
import { OverlayEffect, Mode } from "./store/themeSlice";
import { createTheme, ThemeOptions } from "@mui/material/styles";
import { useMemo } from "react";

declare module "@mui/material/Paper" {
  interface PaperPropsVariantOverrides {
    transparent: true;
  }
}

function getTheme(mode: Mode, overlayEffect: OverlayEffect) {
  const options =
    mode === "DARK"
      ? getDarkThemeWithOverlayEffect(overlayEffect)
      : getLightThemeWithOverlayEffect(overlayEffect);

  return createTheme(options);
}

function ThemeProvider({ children }: { children?: React.ReactNode }) {
  const mode = useThemeStore((state) => state.mode);
  const overlayEffect = useThemeStore((state) => state.overlayEffect);

  const theme = useMemo(() => {
    return getTheme(mode, overlayEffect);
  }, [mode, overlayEffect]);

  return <MuiThemeProvider theme={theme}>{children}</MuiThemeProvider>;
}

export default ThemeProvider;
