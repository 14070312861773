import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import { useLocation, Navigate } from "react-router-dom";
import { useAuthStore } from "./store";

export function RequireAuth({ children }: { children: JSX.Element }) {
  const loading = useAuthStore((state) => state.loading);
  const role = useAuthStore((state) => state.role);
  const location = useLocation();

  if (loading) {
    return (
      <Backdrop open unmountOnExit>
        <CircularProgress />
      </Backdrop>
    );
  }

  if (role !== "AUTHENTICATED") {
    return <Navigate to="/sign-in" state={{ from: location }} replace />;
  }

  return children;
}
