import Avatar from "@mui/material/Avatar";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import Divider from "@mui/material/Divider";
import Stack from "@mui/material/Stack";
import CircularProgress from "@mui/material/CircularProgress";

import LockIcon from "@mui/icons-material/LockRounded";

import EmailForm from "./EmailForm";
import OAuthButtons from "./OAuthButtons";
import Policy from "./Policy";
import { useAuthStore } from "./store";

type SignUpFormProps = {
  onSignIn: () => void;
};

function SignUpForm({ onSignIn }: SignUpFormProps) {
  const userLoading = useAuthStore((state) => state.loading);

  if (userLoading) {
    return (
      <Stack alignItems="center" m={3}>
        <CircularProgress />
      </Stack>
    );
  }

  return (
    <>
      <Container maxWidth="sm">
        <Stack width="100%" alignItems="center" gap={6}>
          <Stack alignItems="center" gap={2} width="100%" maxWidth={300}>
            <Avatar sx={{ m: 1, bgcolor: "secondary.main" }}>
              <LockIcon />
            </Avatar>
            <Typography component="h1" variant="h5">
              Sign Up
            </Typography>
            <OAuthButtons />
            <Divider flexItem sx={{ mt: 1 }} />
            <EmailForm onSignIn={onSignIn} />
          </Stack>
          <Policy />
        </Stack>
      </Container>
    </>
  );
}

export default SignUpForm;
