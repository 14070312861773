import { immer } from "@obr/common/store/middleware/immer";
import create from "zustand";
import { authModalSlice, AuthModalSlice } from "./authModalSlice";
import { authSlice, AuthSlice } from "./authSlice";

export type AuthState = AuthSlice;

export const useAuthStore = create<AuthState>(
  immer((set, get, api) => ({
    ...authSlice(set, get, api),
  }))
);

export type AuthModalState = AuthModalSlice;

export const useAuthModalStore = create<AuthModalState>(
  immer((set, get, api) => ({
    ...authModalSlice(set, get, api),
  }))
);
