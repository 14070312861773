import Avatar from "@mui/material/Avatar";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import Link from "@mui/material/Link";
import Stack from "@mui/material/Stack";
import Divider from "@mui/material/Divider";
import CircularProgress from "@mui/material/CircularProgress";

import LockRoundedIcon from "@mui/icons-material/LockRounded";

import EmailForm from "./EmailForm";
import OAuthButtons from "./OAuthButtons";
import Policy from "./Policy";
import { useAuthStore } from "./store";

type SignInFormProps = {
  onSignIn: () => void;
  onForgotPassword: () => void;
};

function SignInForm({ onSignIn, onForgotPassword }: SignInFormProps) {
  const userLoading = useAuthStore((state) => state.loading);

  if (userLoading) {
    return (
      <Stack alignItems="center" m={3}>
        <CircularProgress />
      </Stack>
    );
  }

  return (
    <Container maxWidth="sm">
      <Stack width="100%" alignItems="center" gap={6}>
        <Stack alignItems="center" gap={2} width="100%" maxWidth={300}>
          <Avatar sx={{ m: 1, bgcolor: "secondary.main" }}>
            <LockRoundedIcon />
          </Avatar>
          <Typography component="h1" variant="h5">
            Sign In
          </Typography>
          <OAuthButtons />
          <Divider flexItem sx={{ mt: 1 }} />
          <EmailForm onSignIn={onSignIn} />
          <Stack width="100%" alignItems="center">
            <Link
              color="inherit"
              underline="hover"
              onClick={(e) => {
                e.preventDefault();
                onForgotPassword();
              }}
            >
              Forgot password?
            </Link>
          </Stack>
        </Stack>
        <Policy />
      </Stack>
    </Container>
  );
}

export default SignInForm;
