import Button from "@mui/material/Button";
import Card from "@mui/material/Card";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";

import LazyMedia from "@obr/common/LazyMedia";

import ImageContainer from "../../ImageContainer";

import homepage from "./homepage.mp4";
import tokens from "./tokens.jpg";
import starter from "./starter.jpg";
import extensions from "./extensions.jpg";
import menu from "./menu.jpg";

function WhatsNew210() {
  return (
    <Card variant="outlined">
      <Stack alignItems="center" p={2}>
        <Typography variant="h5">Owlbear Rodeo 2.1</Typography>
        <Typography variant="caption" color="text.secondary">
          November 2023
        </Typography>
        <ImageContainer>
          <LazyMedia
            video={{ mp4: homepage, width: 960, height: 496 }}
            alt="new home page animation"
          />
        </ImageContainer>
        <Typography variant="body1">
          Owlbear Rodeo 2.1 is here and with it comes some great usability
          enhancements, a new delightful home page and more.
        </Typography>

        <Typography variant="h6" mt={2} mb={1}>
          Streamlined Asset Manager
        </Typography>
        <Typography variant="body1">
          To make Owlbear Rodeo better for new users we've built a brand new
          asset creation experience.
        </Typography>
        <ImageContainer>
          <LazyMedia
            image={{ src: tokens, width: 1280, height: 653 }}
            alt="new token import modal"
          />
        </ImageContainer>

        <Typography variant="h6" mt={2} mb={1}>
          Simpler Starter Sets
        </Typography>
        <Typography variant="body1">
          Starter sets can now be added directly from the asset manager.
        </Typography>
        <ImageContainer>
          <LazyMedia
            image={{ src: starter, width: 1280, height: 649 }}
            alt="new starter sets"
          />
        </ImageContainer>

        <Typography variant="h6" mt={2} mb={1}>
          Easier Extensions
        </Typography>
        <Typography variant="body1">
          Extensions can now be directly installed from the main Owlbear Rodeo
          interface.
        </Typography>
        <ImageContainer>
          <LazyMedia
            image={{ src: extensions, width: 1280, height: 653 }}
            alt="new extensions page"
          />
        </ImageContainer>

        <Typography variant="h6" mt={2} mb={1}>
          Expandable Context Menu
        </Typography>
        <Typography variant="body1">
          The context menu is now better organized and easier to use.
        </Typography>
        <ImageContainer>
          <LazyMedia
            image={{ src: menu, width: 1262, height: 836 }}
            alt="new context menu"
          />
        </ImageContainer>

        <Button
          href="https://blog.owlbear.rodeo/owlbear-rodeo-2-1-release-notes/"
          rel="noopener noreferrer"
          target="_blank"
          fullWidth
          sx={{ mt: 2 }}
        >
          See more
        </Button>
      </Stack>
    </Card>
  );
}

export default WhatsNew210;
