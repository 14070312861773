import { StateCreator } from "zustand";

export interface WhatsNewModalSlice {
  open: boolean;
  openWhatsNew: () => void;
  closeWhatsNew: () => void;
}

export const whatsNewModalSlice: StateCreator<WhatsNewModalSlice> = (set) => ({
  open: false,
  openWhatsNew: () => {
    set((state) => {
      state.open = true;
    });
  },
  closeWhatsNew: () => {
    set((state) => {
      state.open = false;
    });
  },
});
