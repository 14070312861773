import { immer } from "@obr/common/store/middleware/immer";
import create from "zustand";
import { ShortcutsSlice, shortcutsSlice } from "./shortcutsSlice";

export type ShortcutsState = ShortcutsSlice;

export const useShortcutsStore = create<ShortcutsState>(
  immer((set, get, api) => ({
    ...shortcutsSlice(set, get, api),
  }))
);
