import throttle from "lodash.throttle";

export function handleChallengeURLParam() {
  const page = new URL(window.location.href);

  if (page.searchParams.get("challenge") !== null) {
    page.searchParams.delete("challenge");

    history.replaceState({}, document.title, page);
  }
}

export function handleRetryWithBotChallenge(
  failureCount: number,
  error: unknown
) {
  if (failureCount >= 3) {
    return false;
  }
  if (error instanceof Error) {
    if (error.message !== null) {
      try {
        const msg = JSON.parse(error.message);
        const challenge = msg.challenge;
        if (challenge) {
          return false;
        }
      } catch {
        return true;
      }
    }
  }

  return true;
}

export async function expectNotBot(response: Response) {
  const lastRefreshTime = sessionStorage.getItem("challenge");

  const minute = 1000 * 60 * 1;
  const minuteAgo = Date.now() - minute;

  if (lastRefreshTime) {
    const refreshTime = parseInt(lastRefreshTime);
    if (!isNaN(refreshTime)) {
      // less than a minute ago
      if (refreshTime > minuteAgo) {
        return;
      }
    };
  }

  if (response.headers.get("cf-mitigated") === "challenge") {
    issueBotChallenge();
    const error = {
      status: response.status,
      challenge: true,
      text: "Bot challenge must be completed",
      details: await response.text(),
    };
    throw Error(JSON.stringify(error));
  }
}

export const issueBotChallenge = throttle(
  () => {
    const currentPage = new URL(window.location.href);

    if (currentPage.searchParams.get("challenge")) {
      return;
    }

    sessionStorage.setItem("challenge", Date.now().toString());

    currentPage.searchParams.append("challenge", "true");

    window.location.href = currentPage.toString();

    return;
  },
  300000,
  { leading: true, trailing: false }
);
