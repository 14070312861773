import React from "react";
import ReactDOM from "react-dom/client";
import App from "./app/App";
import CssBaseline from "@mui/material/CssBaseline";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";

// Add css for simplebar
import "simplebar/dist/simplebar.min.css";

import "./index.css";
import "./fonts/fonts.css";

import NotificationsProvider from "@obr/features/notifications/NotificationsProvider";
import ErrorBoundary from "@obr/common/ErrorBoundary";
import ThemeProvider from "@obr/features/theme/ThemeProvider";
import GlobalStyles from "@obr/features/theme/GlobalStyles";

export const queryClient = new QueryClient();

ReactDOM.createRoot(document.getElementById("root") as HTMLElement).render(
  <React.StrictMode>
    <QueryClientProvider client={queryClient}>
      <ThemeProvider>
        <CssBaseline />
        <GlobalStyles />
        <NotificationsProvider>
          <ErrorBoundary>
            <App />
          </ErrorBoundary>
        </NotificationsProvider>
      </ThemeProvider>
    </QueryClientProvider>
  </React.StrictMode>
);
