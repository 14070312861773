import Button from "@mui/material/Button";
import Card from "@mui/material/Card";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";

import LazyMedia from "@obr/common/LazyMedia";

import ImageContainer from "../../ImageContainer";

import header from "./header.jpg";
import fog from "./fog.mp4";
import weather from "./weather.mp4";
import image from "./image.jpg";

function WhatsNew230() {
  return (
    <Card variant="outlined">
      <Stack alignItems="center" p={2}>
        <Typography variant="h5">Owlbear Rodeo 2.3</Typography>
        <Typography variant="caption" color="text.secondary">
          October 2024
        </Typography>
        <ImageContainer>
          <LazyMedia
            image={{ src: header, width: 1280, height: 720 }}
            alt="header"
          />
        </ImageContainer>
        <Typography variant="body1">
          Owlbear Rodeo 2.3 is out with the new Warp Core engine. Great
          performance, beautiful dynamic fog and new GPU effects.
        </Typography>

        <Typography variant="h6" mt={2} mb={1}>
          Realtime Dynamic Fog
        </Typography>
        <Typography variant="body1">
          We've build new dynamic fog options and a new extension to show them
          off.
        </Typography>
        <ImageContainer>
          <LazyMedia
            video={{ mp4: fog, width: 960, height: 482 }}
            alt="realtime dynamic fog"
          />
        </ImageContainer>

        <Typography variant="h6" mt={2} mb={1}>
          GPU Effects
        </Typography>
        <Typography variant="body1">
          Effects allow developers to build GPU shaders. To showcase this we've
          build a new Weather extension.
        </Typography>
        <ImageContainer>
          <LazyMedia
            video={{ mp4: weather, width: 960, height: 482 }}
            alt="new starter sets"
          />
        </ImageContainer>

        <Typography variant="h6" mt={2} mb={1}>
          Larger Map Support
        </Typography>
        <Typography variant="body1">
          We've added a new tiled renderer for large maps and images.
        </Typography>
        <ImageContainer>
          <LazyMedia
            image={{ src: image, width: 1280, height: 720 }}
            alt="larger map size logo"
          />
        </ImageContainer>

        <Button
          href="https://blog.owlbear.rodeo/owlbear-rodeo-2-3-release-notes/"
          rel="noopener noreferrer"
          target="_blank"
          fullWidth
          sx={{ mt: 2 }}
        >
          See more
        </Button>
      </Stack>
    </Card>
  );
}

export default WhatsNew230;
