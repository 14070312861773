import { useState } from "react";

import Backdrop from "@mui/material/Backdrop";
import Button from "@mui/material/Button";
import CircularProgress from "@mui/material/CircularProgress";
import Stack from "@mui/material/Stack";

import { useNotificationsStore } from "@obr/features/notifications/store";
import { useAuthStore } from "./store";

import GoogleIcon from "@obr/icons/Google";
import AppleIcon from "@obr/icons/Apple";

function OAuthButtons() {
  const [loading, setLoading] = useState(false);
  const signInWithOAuth = useAuthStore((state) => state.signInWithOAuth);
  const showNotification = useNotificationsStore(
    (state) => state.showNotification
  );

  async function signInWithProvider(provider: "google" | "apple") {
    try {
      setLoading(true);
      await signInWithOAuth({
        provider,
        options: {
          redirectTo: window.location.href,
        },
      });
      setLoading(false);
    } catch (error) {
      console.error(error);
      setLoading(false);
      if (error instanceof Error) {
        showNotification(error.message, { variant: "error" });
      } else {
        showNotification("Unknown error occurred", { variant: "error" });
      }
    }
  }

  if (import.meta.env.VITE_DISABLE_SOCIAL_SIGN_IN) {
    return null;
  }

  return (
    <Stack gap={2} width="100%">
      <Button
        startIcon={<GoogleIcon />}
        variant="contained"
        sx={{
          backgroundColor: "white",
          ":hover": {
            backgroundColor: "#dddddd",
          },
          color: "rgba(0, 0, 0, 0.87)",
        }}
        fullWidth
        onClick={() => signInWithProvider("google")}
      >
        Continue with Google
      </Button>
      <Button
        startIcon={<AppleIcon />}
        variant="contained"
        sx={{
          backgroundColor: "white",
          ":hover": {
            backgroundColor: "#dddddd",
          },
          color: "rgba(0, 0, 0, 0.87)",
        }}
        fullWidth
        onClick={() => signInWithProvider("apple")}
      >
        Continue with Apple
      </Button>
      <Backdrop open={loading} unmountOnExit>
        <CircularProgress />
      </Backdrop>
    </Stack>
  );
}

export default OAuthButtons;
