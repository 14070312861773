import produce from "immer";
import type { Draft } from "immer";
import { State, StateCreator } from "zustand/vanilla";

/**
 * Immer Zustand middleware
 *
 * @example <caption>Using immer</caption>
 * const useState = create(
 *  immer((set) => ({
 *    bees: false,
 *    setBees: (input) => set((state) => { state.bees = input; }),
 *  }))
 * );
 *
 * @param config The initial state of the store we should be using.
 * @returns A Zustand state creator.
 */
export const immer =
  <S extends State>(config: StateCreator<S>): StateCreator<S> =>
  (set, get, api) =>
    config(
      (partial, replace) => {
        const nextState =
          typeof partial === "function"
            ? produce(partial as (state: Draft<S>) => S)
            : (partial as S);
        return set(nextState, replace);
      },
      get,
      api
    );
