import { createClient } from "@supabase/supabase-js";
import embeddableStorage from "@obr/features/embed/embeddableStorage";
import { Database } from "./types/Database";

const supabaseUrl = import.meta.env.VITE_SUPABASE_URL!;
const supabaseAnonKey = import.meta.env.VITE_SUPABASE_KEY!;

export const supabase = createClient<Database>(supabaseUrl, supabaseAnonKey, {
  auth: {
    storage: embeddableStorage,
  },
});
