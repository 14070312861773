import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import { isEmbedded } from "../embed";
import EmbedSignInForm from "./EmbedSignInForm";
import ResetPasswordForm from "./ResetPasswordForm";
import SignInForm from "./SignInForm";
import SignUpForm from "./SignUpForm";
import { useAuthModalStore } from "./store";

const embedded = isEmbedded();

function AuthModal() {
  const mode = useAuthModalStore((state) => state.mode);
  const changeMode = useAuthModalStore((state) => state.changeMode);
  const closeModal = useAuthModalStore((state) => state.closeAuth);

  const open = Boolean(mode);

  function renderContents() {
    if (mode === "SIGN_IN") {
      if (embedded) {
        return (
          <EmbedSignInForm
            onSignIn={closeModal}
            onForgotPassword={() => changeMode("RECOVER")}
          />
        );
      } else {
        return (
          <SignInForm
            onSignIn={closeModal}
            onForgotPassword={() => changeMode("RECOVER")}
          />
        );
      }
    } else if (mode === "SIGN_UP") {
      if (embedded) {
        return null;
      } else {
        return <SignUpForm onSignIn={closeModal} />;
      }
    } else if (mode === "RECOVER") {
      return <ResetPasswordForm onSuccess={() => {}} />;
    } else {
      return null;
    }
  }

  return (
    <Dialog open={open} onClose={closeModal}>
      <DialogContent>{renderContents()}</DialogContent>
    </Dialog>
  );
}

export default AuthModal;
