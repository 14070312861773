import { useState } from "react";

import Avatar from "@mui/material/Avatar";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import Link from "@mui/material/Link";
import Stack from "@mui/material/Stack";
import Divider from "@mui/material/Divider";
import CircularProgress from "@mui/material/CircularProgress";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import Button from "@mui/material/Button";

import LockRoundedIcon from "@mui/icons-material/LockRounded";

import OwlbearIcon from "@obr/icons/Owlbear";

import EmailForm from "./EmailForm";
import Policy from "./Policy";
import { useAuthStore } from "./store";
import StoragePromptForm from "./StoragePromptForm";

type EmbedSignInFormProps = {
  onSignIn: () => void;
  onForgotPassword: () => void;
};

function EmbedSignInForm({ onSignIn, onForgotPassword }: EmbedSignInFormProps) {
  const userLoading = useAuthStore((state) => state.loading);
  const [promptOpen, setPromptOpen] = useState(false);

  if (userLoading) {
    return (
      <Stack alignItems="center" m={3}>
        <CircularProgress />
      </Stack>
    );
  }

  return (
    <Container maxWidth="sm">
      <Stack width="100%" alignItems="center" gap={6}>
        <Stack alignItems="center" gap={2} width="100%" maxWidth={300}>
          <Avatar sx={{ m: 1, bgcolor: "secondary.main" }}>
            <LockRoundedIcon />
          </Avatar>
          <Typography component="h1" variant="h5">
            Sign In
          </Typography>
          <Button
            startIcon={<OwlbearIcon />}
            variant="contained"
            sx={{
              backgroundColor: "white",
              ":hover": {
                backgroundColor: "#dddddd",
              },
              color: "rgba(0, 0, 0, 0.87)",
            }}
            fullWidth
            onClick={() => setPromptOpen(true)}
          >
            Use Current Session
          </Button>
          <Divider flexItem sx={{ mt: 1 }} />
          <EmailForm onSignIn={onSignIn} />
          <Stack width="100%" alignItems="center">
            <Link
              color="inherit"
              underline="hover"
              onClick={(e) => {
                e.preventDefault();
                onForgotPassword();
              }}
            >
              Forgot password?
            </Link>
          </Stack>
        </Stack>
        <Policy />
      </Stack>
      <Dialog open={promptOpen} onClose={() => setPromptOpen(false)}>
        <DialogContent>
          <StoragePromptForm
            onSignIn={() => {
              setPromptOpen(false);
              onSignIn();
            }}
          />
        </DialogContent>
      </Dialog>
    </Container>
  );
}

export default EmbedSignInForm;
