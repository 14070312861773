import { useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";

import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import Button from "@mui/material/Button";
import Stack from "@mui/material/Stack";
import IconButton from "@mui/material/IconButton";
import MenuItem from "@mui/material/MenuItem";
import MuiLink from "@mui/material/Link";
import Dialog from "@mui/material/Dialog";
import MenuList from "@mui/material/MenuList";
import styled from "@mui/material/styles/styled";
import Skeleton from "@mui/material/Skeleton";
import useMediaQuery from "@mui/material/useMediaQuery";

import MenuIcon from "@mui/icons-material/MenuRounded";
import CloseIcon from "@mui/icons-material/CloseRounded";

import { useAuthStore } from "@obr/features/auth/store";
import WhatsNewButton from "@obr/features/whatsNew/WhatsNewButton";

import logo from "../logo.svg";

const SmallMenuItem = styled(MenuItem)({
  paddingLeft: 24,
  paddingRight: 24,
});

function NavBar() {
  const small = useMediaQuery("@media (max-width:725px)");
  const [open, setOpen] = useState(false);
  const handleClick = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  const role = useAuthStore((state) => state.role);
  const userLoading = useAuthStore((state) => state.loading);

  const location = useLocation();
  const navigate = useNavigate();

  if (
    location.pathname.startsWith("/room") ||
    location.pathname.startsWith("/embed")
  ) {
    return null;
  }

  return (
    <AppBar
      position="static"
      elevation={0}
      sx={{
        backgroundColor: location.pathname === "/" ? "transparent" : undefined,
        color: location.pathname === "/" ? "white" : undefined,
      }}
    >
      <Toolbar sx={{ justifyContent: "space-between" }}>
        {small ? (
          <>
            <IconButton
              component={Link}
              to="/"
              sx={{ width: "40px", height: "40px", padding: 0.5 }}
            >
              <img src={logo} alt="logo" />
            </IconButton>
            <div>
              {role === "AUTHENTICATED" && <WhatsNewButton />}
              <IconButton
                id="navigation-button"
                aria-controls="navigation-menu"
                aria-haspopup="true"
                aria-expanded={open ? "true" : undefined}
                onClick={handleClick}
              >
                <MenuIcon />
              </IconButton>
              <Dialog
                id="navigation-menu"
                open={open}
                onClose={handleClose}
                fullScreen
              >
                <Toolbar
                  sx={{
                    justifyContent: "space-between",
                    backgroundColor: "rgba(255, 255, 255, 0.02)",
                  }}
                >
                  <IconButton
                    onClick={() => {
                      navigate("/");
                      handleClose();
                    }}
                    sx={{ width: "40px", height: "40px", padding: 0.5 }}
                  >
                    <img src={logo} alt="logo" />
                  </IconButton>
                  <IconButton aria-label="close" onClick={handleClose}>
                    <CloseIcon />
                  </IconButton>
                </Toolbar>
                <MenuList aria-labelledby="navigation-button" sx={{ mb: 2 }}>
                  <SmallMenuItem
                    onClick={() => {
                      navigate("/");
                      handleClose();
                    }}
                    selected={location.pathname === "/"}
                  >
                    Overview
                  </SmallMenuItem>
                  <SmallMenuItem
                    onClick={() => {
                      window.location.href = "https://docs.owlbear.rodeo";
                    }}
                  >
                    Docs
                  </SmallMenuItem>
                  <SmallMenuItem
                    onClick={() => {
                      window.location.href = "https://blog.owlbear.rodeo";
                    }}
                  >
                    Blog
                  </SmallMenuItem>
                  <SmallMenuItem
                    onClick={() => {
                      window.location.href = "https://extensions.owlbear.rodeo";
                    }}
                  >
                    Extensions
                  </SmallMenuItem>
                  <SmallMenuItem
                    onClick={() => {
                      navigate("/pricing");
                      handleClose();
                    }}
                    selected={location.pathname === "/pricing"}
                  >
                    Pricing
                  </SmallMenuItem>
                  <SmallMenuItem
                    onClick={() => {
                      navigate("/about");
                      handleClose();
                    }}
                    selected={location.pathname === "/about"}
                  >
                    About
                  </SmallMenuItem>
                </MenuList>
                <Stack width="100%" gap={2} px={2}>
                  {userLoading ? (
                    <Skeleton
                      height={40}
                      width="100%"
                      variant="rectangular"
                      sx={{ borderRadius: 20 }}
                    />
                  ) : role === "AUTHENTICATED" ? (
                    <Button
                      variant="outlined"
                      fullWidth
                      onClick={() => {
                        navigate("/profile");
                        handleClose();
                      }}
                    >
                      Profile
                    </Button>
                  ) : (
                    <>
                      <Button
                        variant="outlined"
                        fullWidth
                        onClick={() => {
                          navigate("/sign-in");
                          handleClose();
                        }}
                      >
                        Sign In
                      </Button>
                      <Button
                        variant="contained"
                        fullWidth
                        onClick={() => {
                          navigate("/sign-up");
                          handleClose();
                        }}
                      >
                        Play Free
                      </Button>
                    </>
                  )}
                </Stack>
              </Dialog>
            </div>
          </>
        ) : (
          <>
            <Stack direction="row" gap={1}>
              <IconButton
                component={Link}
                to="/"
                sx={{ width: "40px", height: "40px", padding: 0.5 }}
              >
                <img src={logo} alt="logo" />
              </IconButton>
              <Button
                color={location.pathname === "/" ? "primary" : "inherit"}
                component={Link}
                to="/"
              >
                Overview
              </Button>
              <Button
                color="inherit"
                component={MuiLink}
                href="https://docs.owlbear.rodeo"
                rel="noopener noreferrer"
                target="_blank"
              >
                Docs
              </Button>
              <Button
                color="inherit"
                component={MuiLink}
                href="https://blog.owlbear.rodeo"
                rel="noopener noreferrer"
                target="_blank"
              >
                Blog
              </Button>
              <Button
                color="inherit"
                component={MuiLink}
                href="https://extensions.owlbear.rodeo"
                rel="noopener noreferrer"
                target="_blank"
              >
                Extensions
              </Button>
              <Button
                color={location.pathname === "/pricing" ? "primary" : "inherit"}
                component={Link}
                to="/pricing"
              >
                Pricing
              </Button>
            </Stack>
            <Stack direction="row" gap={1}>
              {userLoading ? (
                <Skeleton
                  height={40}
                  width={60}
                  variant="rectangular"
                  sx={{ borderRadius: 20 }}
                />
              ) : role === "AUTHENTICATED" ? (
                <>
                  <WhatsNewButton />
                  <Button variant="contained" component={Link} to="/profile">
                    Profile
                  </Button>
                </>
              ) : (
                <>
                  <Button color="inherit" component={Link} to="/sign-in">
                    Sign In
                  </Button>
                  <Button variant="contained" component={Link} to="/sign-up">
                    Play Free
                  </Button>
                </>
              )}
            </Stack>
          </>
        )}
      </Toolbar>
    </AppBar>
  );
}

export default NavBar;
