import { immer } from "@obr/common/store/middleware/immer";
import create from "zustand";

import { notificationsSlice, NotificationsSlice } from "./notificationsSlice";

export type NotificationsState = NotificationsSlice;

export const useNotificationsStore = create<NotificationsState>(
  immer((set, get, api) => ({
    ...notificationsSlice(set, get, api),
  }))
);
