import Button from "@mui/material/Button";
import Card from "@mui/material/Card";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";

import LazyMedia from "@obr/common/LazyMedia";

import ImageContainer from "../../ImageContainer";

import header from "./header.jpg";
import assetManager from "./assetManager.jpg";
import drop from "./drop.mp4";
import outliner from "./outliner.jpg";
import quickSelect from "./quickSelect.mp4";
import starter from "./starter.jpg";

function WhatsNew220() {
  return (
    <Card variant="outlined">
      <Stack alignItems="center" p={2}>
        <Typography variant="h5">Owlbear Rodeo 2.2</Typography>
        <Typography variant="caption" color="text.secondary">
          February 2024
        </Typography>
        <ImageContainer>
          <LazyMedia
            image={{ src: header, width: 1280, height: 647 }}
            alt="header"
          />
        </ImageContainer>
        <Typography variant="body1">
          Owlbear Rodeo 2.2 is out with further improvements to the asset
          workflow, new starter sets, new extensions and more.
        </Typography>

        <Typography variant="h6" mt={2} mb={1}>
          Combined Asset Manager
        </Typography>
        <Typography variant="body1">
          To make Owlbear Rodeo simpler we've combined the Atlas and Token
          Manager into one Asset Manager.
        </Typography>
        <ImageContainer>
          <LazyMedia
            image={{ src: assetManager, width: 1280, height: 647 }}
            alt="new asset manager"
          />
        </ImageContainer>

        <Typography variant="h6" mt={2} mb={1}>
          New Starter Sets
        </Typography>
        <Typography variant="body1">
          We now have our first third-party Starter Sets with a set of 12 maps
          from Czepeku.
        </Typography>
        <ImageContainer>
          <LazyMedia
            image={{ src: starter, width: 1280, height: 647 }}
            alt="new starter sets"
          />
        </ImageContainer>

        <Typography variant="h6" mt={2} mb={1}>
          New Extensions
        </Typography>
        <Typography variant="body1">
          We've created two new extensions Outliner and Prefabs to help
          streamline advanced use cases.
        </Typography>
        <ImageContainer>
          <LazyMedia
            image={{ src: outliner, width: 1280, height: 630 }}
            alt="new outliner extension"
          />
        </ImageContainer>

        <Typography variant="h6" mt={2} mb={1}>
          Better Asset Import
        </Typography>
        <Typography variant="body1">
          When importing new images directly into a scene you can now select
          which type to use with an import wheel.
        </Typography>
        <ImageContainer>
          <LazyMedia
            video={{ mp4: drop, width: 960, height: 486 }}
            alt="asset import wheel"
          />
        </ImageContainer>

        <Typography variant="h6" mt={2} mb={1}>
          Fog Quick Select
        </Typography>
        <Typography variant="body1">
          We've added a new tool to make using fog easier when both playing and
          preparing.
        </Typography>
        <ImageContainer>
          <LazyMedia
            video={{ mp4: quickSelect, width: 960, height: 486 }}
            alt="fog quick select"
          />
        </ImageContainer>

        <Button
          href="https://blog.owlbear.rodeo/owlbear-rodeo-2-2-release-notes/"
          rel="noopener noreferrer"
          target="_blank"
          fullWidth
          sx={{ mt: 2 }}
        >
          See more
        </Button>
      </Stack>
    </Card>
  );
}

export default WhatsNew220;
