import { useEffect } from "react";
import { Outlet, useNavigate } from "react-router-dom";

import { useAuthStore } from "@obr/features/auth/store";

function AuthStatus() {
  const navigate = useNavigate();
  const role = useAuthStore((state) => state.role);
  const shouldRedirect = useAuthStore((state) => state.shouldRedirect);

  useEffect(() => {
    if (
      shouldRedirect &&
      role === "AUTHENTICATED" &&
      (window.location.pathname === "/sign-in" ||
        window.location.pathname === "/sign-up")
    ) {
      navigate("/profile");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [shouldRedirect, role]);

  return <Outlet></Outlet>;
}

export default AuthStatus;
