import Link from "@mui/material/Link";
import Typography from "@mui/material/Typography";

function Policy() {
  return (
    <Typography variant="caption" color="text.secondary" textAlign="center">
      By clicking “Continue with Google/Apple/Email” above, you acknowledge that
      you have read and understood, and agree to Owlbear Rodeo's{" "}
      <Link
        href="http://www.owlbear.rodeo/terms"
        underline="always"
        color="inherit"
        variant="caption"
        rel="noopener noreferrer"
        target="_blank"
      >
        Terms {"&"} Conditions
      </Link>{" "}
      and{" "}
      <Link
        href="http://www.owlbear.rodeo/privacy"
        underline="always"
        color="inherit"
        variant="caption"
        rel="noopener noreferrer"
        target="_blank"
      >
        Privacy Policy
      </Link>
      .
    </Typography>
  );
}

export default Policy;
