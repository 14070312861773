import Badge from "@mui/material/Badge";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import { useWhatsNewModalStore, useWhatsNewStore } from "./store";

import WhatsNewIcon from "@mui/icons-material/AutoAwesomeRounded";

/**
 * @param level 0 - Major, 1 - Minor, 2 - Patch
 */
export function compareVersions(prev: string, curr: string, level = 1) {
  const prevParts = prev.split(".");
  const currParts = curr.split(".");

  if (prevParts.length !== 3 || currParts.length !== 3) {
    throw new Error("Received invalid version string");
  }

  for (let i = 0; i <= level; i++) {
    if (prevParts[i] !== currParts[i]) {
      return true;
    }
  }
  return false;
}

function WhatsNewButton() {
  const openWhatsNew = useWhatsNewModalStore((state) => state.openWhatsNew);
  const lastSeenAppVersion = useWhatsNewStore(
    (state) => state.lastSeenAppVersion
  );

  const showBadge = Boolean(
    lastSeenAppVersion && compareVersions(lastSeenAppVersion, APP_VERSION)
  );

  return (
    <Badge
      invisible={!showBadge}
      color="primary"
      variant="dot"
      overlap="circular"
    >
      <span>
        <Tooltip title="What's New">
          <IconButton onClick={openWhatsNew}>
            <WhatsNewIcon />
          </IconButton>
        </Tooltip>
      </span>
    </Badge>
  );
}

export default WhatsNewButton;
