import { isEmbedded } from ".";
import { FakeStorage } from "./FakeStorage";
import { SupportedStorage } from "./types/SupportedStorage";

/**
 * An adapter that sits on top of localStorage, FakeStorage and the
 * Storage Access API to determine which storage handle to use
 */
export class MediatedStorage implements SupportedStorage {
  static #handle: Promise<SupportedStorage>;
  static usingFallback: true | undefined;
  constructor() {}

  private static async getHandle() {
    if (!MediatedStorage.#handle) {
      if (isEmbedded()) {
        MediatedStorage.#handle = MediatedStorage.getEmbeddedStorage();
      } else {
        MediatedStorage.#handle = Promise.resolve(
          MediatedStorage.getSafeStorage()
        );
      }
    }
    return MediatedStorage.#handle;
  }

  private static async getEmbeddedStorage(): Promise<SupportedStorage> {
    console.log("Checking embed storage...");
    // Check if the storage access API is available
    if (!document.hasStorageAccess) {
      console.warn(
        "Storage access API unavailable. Account usage may be unavailable"
      );
      return this.getSafeStorage();
    }

    const hasAccess = await document.hasStorageAccess();
    if (hasAccess) {
      console.log("Has storage access for local storage");
      return await MediatedStorage.getGrantedStorage();
    } else {
      console.warn(
        "No access to unpartitioned storage. Account usage will be unavailable"
      );
      return this.getSafeStorage();
    }
  }

  private static async getGrantedStorage(): Promise<SupportedStorage> {
    try {
      // Reason: We're using the StorageAccessHandle which is only supported in Chrome
      // https://developer.mozilla.org/en-US/docs/Web/API/StorageAccessHandle
      // @ts-ignore
      const handle = (await document.requestStorageAccess({
        all: true,
      })) as unknown;

      // Reason: as above
      // @ts-ignore
      if (handle && handle.localStorage) {
        console.log(
          "Unpartitioned storage handle available. Account usage available"
        );
        // Reason: as above
        // @ts-ignore
        return handle.localStorage;
      } else {
        MediatedStorage.usingFallback = true;
        console.warn(
          "No unpartitioned storage handle available. Account usage will be unavailable"
        );
        return this.getSafeStorage();
      }
    } catch (err) {
      console.error(err);
      MediatedStorage.usingFallback = true;
      console.warn("Storage request failed. Account usage will be unavailable");
      return this.getSafeStorage();
    }
  }

  private static getSafeStorage(): SupportedStorage {
    const randomKey = `lswt-${Math.random()}${Math.random()}`;
    // Try and use local storage if it is available, if not mock it with an in memory storage
    // This happens most often when third-party cookies are disabled and Owlbear is embedded
    let storageAvailable = true;
    try {
      localStorage.setItem(randomKey, randomKey);
      localStorage.removeItem(randomKey);
    } catch (e) {
      MediatedStorage.usingFallback = true;
      // https://www.chromium.org/for-testers/bug-reporting-guidelines/uncaught-securityerror-failed-to-read-the-localstorage-property-from-window-access-is-denied-for-this-document
      console.warn("Local storage is disabled, no settings will be saved");
      storageAvailable = false;
    }
    return storageAvailable ? localStorage : new FakeStorage();
  }

  async getItem(key: string): Promise<string | null> {
    return (await MediatedStorage.getHandle()).getItem(key);
  }

  async setItem(key: string, value: string): Promise<void> {
    return (await MediatedStorage.getHandle()).setItem(key, value);
  }

  async removeItem(key: string): Promise<void> {
    return (await MediatedStorage.getHandle()).removeItem(key);
  }
}
