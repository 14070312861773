import Box from "@mui/material/Box";
import Skeleton from "@mui/material/Skeleton";
import { useTheme } from "@mui/material/styles";
import { useInView } from "react-intersection-observer";

function LazyMedia({
  image,
  video,
  alt,
  rootMargin,
}: {
  image?: { src: string; width: number; height: number };
  video?: {
    mp4?: string;
    webm?: string;
    width: number;
    height: number;
  };
  alt: string;
  rootMargin?: string;
}) {
  const theme = useTheme();

  const [ref, inView] = useInView({ triggerOnce: true, rootMargin });

  const width = video?.width ?? image?.width ?? 1;
  const height = video?.height ?? image?.height ?? 1;
  const ratio = height / width;

  const placeholder = (
    <Skeleton
      animation={false}
      width="100%"
      height="0"
      sx={{ pt: `${ratio * 100}%` }}
      variant="rectangular"
    />
  );

  return (
    <Box
      ref={ref}
      sx={{
        width: "100%",
        display: "flex",
        transition: theme.transitions.create("opacity", {
          duration: theme.transitions.duration.complex,
        }),
        opacity: inView ? 1 : 0,
      }}
    >
      {inView ? (
        video ? (
          <video
            playsInline
            muted
            autoPlay
            loop
            style={{ width: "100%", height: "100%" }}
            poster={image?.src}
            width={video.width}
            height={video.height}
          >
            {video.webm && <source src={video.webm} type="video/webm" />}
            {video.mp4 && <source src={video.mp4} type="video/mp4" />}
            <meta itemProp="name" content={alt} />
          </video>
        ) : image ? (
          <img
            src={image.src}
            alt={alt}
            loading="lazy"
            style={{ width: "100%", height: "100%" }}
            width={image.width}
            height={image.height}
          />
        ) : null
      ) : (
        placeholder
      )}
    </Box>
  );
}

export default LazyMedia;
