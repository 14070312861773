import LoadingButtonBase from "@mui/lab/LoadingButton";
import { ButtonProps } from "@mui/material/Button";
import React from "react";

/**
 * @description Adds a span to children of the LoadingButton. This is a fix to "'insertBefore' on 'Node'" errors in React.
 * https://github.com/facebook/react/issues/11538
 */
function LoadingButton<D extends React.ElementType = "button">({
  children,
  ...rest
}: ButtonProps<D> & {
  loading?: boolean;
  component?: D;
  loadingIndicator?: React.ReactNode;
}) {
  return (
    <LoadingButtonBase {...rest}>
      <span>{children}</span>
    </LoadingButtonBase>
  );
}

export default LoadingButton;
