import { StateCreator } from "zustand";

export type AuthMode = "SIGN_IN" | "SIGN_UP" | "RECOVER";

export interface AuthModalSlice {
  mode?: AuthMode;
  openAuth: (mode: AuthMode) => void;
  closeAuth: () => void;
  changeMode: (mode: AuthMode) => void;
}

export const authModalSlice: StateCreator<AuthModalSlice> = (set) => ({
  openAuth: (mode) => {
    set((state) => {
      state.mode = mode;
    });
  },
  closeAuth: () => {
    set((state) => {
      state.mode = undefined;
    });
  },
  changeMode: (mode) => {
    set((state) => {
      state.mode = mode;
    });
  },
});
