import { useNotificationsStore } from "@obr/features/notifications/store";
import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";

function isDBPath(path: string) {
  return (
    path === "/sign-in" ||
    path === "/sign-up" ||
    path === "/profile" ||
    path.startsWith("/room")
  );
}

function MaintenanceNotification() {
  const showNotification = useNotificationsStore(
    (state) => state.showNotification
  );
  const location = useLocation();
  const [shown, setShown] = useState(false);

  useEffect(() => {
    const message = import.meta.env.VITE_MAINTENANCE_MESSAGE as string;
    if (!shown && message && isDBPath(location.pathname)) {
      const split = message.split(/(:t:[^ ]*)/);
      const newMessage: string[] = [];
      for (let text of split) {
        if (text.startsWith(":t:")) {
          const time = new Date(text.slice(3));
          newMessage.push(
            time.toLocaleString(undefined, {
              dateStyle: "full",
              timeStyle: "short",
              hour12: true,
            })
          );
        } else {
          newMessage.push(text);
        }
      }
      setShown(true);
      showNotification(newMessage.join(""), {
        variant: "info",
        persist: true,
      });
    }
  }, [showNotification, location.pathname, shown]);

  return null;
}

export default MaintenanceNotification;
