import { StateCreator } from "zustand";
import { OptionsObject, SnackbarMessage } from "notistack";
import { v4 as uuid } from "uuid";

export interface Notification {
  key: string;
  message: SnackbarMessage;
  options?: OptionsObject;
  closed?: boolean;
}

export interface NotificationsSlice {
  notifications: Notification[];
  showNotification: (
    message: SnackbarMessage,
    options?: OptionsObject
  ) => string;
  /**
   * Closes a shown notification
   * This will set the closed state to true
   * The notification provider will then remove it once it is done closing
   */
  closeNotification: (key: string) => void;
  /**
   * Removes a closed notification
   * Should only be called by the notification provider
   */
  removeNotification: (key: string) => void;
}

export const notificationsSlice: StateCreator<NotificationsSlice> = (set) => ({
  notifications: [],
  showNotification: (message, options) => {
    const key = uuid();
    set((state) => {
      state.notifications.push({
        key,
        message,
        options,
      });
    });
    return key;
  },
  closeNotification: (key) => {
    set((state) => {
      const notification = state.notifications.find((n) => n.key === key);
      if (notification) {
        notification.closed = true;
      }
    });
  },
  removeNotification: (key) => {
    set((state) => {
      const index = state.notifications.findIndex((n) => n.key === key);
      if (index > -1) {
        state.notifications.splice(index, 1);
      }
    });
  },
});
