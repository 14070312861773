import { useSnackbar, SnackbarProvider } from "notistack";
import { useEffect, useRef } from "react";
import Grow from "@mui/material/Grow";
import styled from "@mui/material/styles/styled";
import Button from "@mui/material/Button";

import { useNotificationsStore } from "./store";

const StyledSnackbarProvider = styled(SnackbarProvider)(({ theme }) => ({
  "&.SnackbarItem-contentRoot": {
    backgroundColor: theme.palette.background.paper,
    backgroundImage:
      "linear-gradient(rgba(255, 255, 255, 0.1), rgba(255, 255, 255, 0.1))",
    color: theme.palette.text.primary,
    borderRadius: 16,
  },
  "&.SnackbarItem-variantError": {
    backgroundColor: theme.palette.error.dark,
  },
  "&.SnackbarItem-variantWarning": {
    backgroundColor: theme.palette.warning.dark,
  },
  "&.SnackbarItem-variantSuccess": {
    backgroundColor: theme.palette.success.dark,
  },
}));

function CustomSnackbarProvider({ children }: { children: React.ReactNode }) {
  const notistackRef = useRef<SnackbarProvider>(null);

  return (
    <StyledSnackbarProvider
      ref={notistackRef}
      anchorOrigin={{
        vertical: "top",
        horizontal: "center",
      }}
      TransitionComponent={Grow}
      action={(key) => (
        <Button
          onClick={() => notistackRef.current?.closeSnackbar(key)}
          color="inherit"
        >
          Dismiss
        </Button>
      )}
    >
      <NotificationsProvider>{children}</NotificationsProvider>
    </StyledSnackbarProvider>
  );
}

function NotificationsProvider({ children }: { children: React.ReactNode }) {
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  const notifications = useNotificationsStore((state) => state.notifications);
  const removeNotification = useNotificationsStore(
    (state) => state.removeNotification
  );

  const displayedNotificationsRef = useRef(new Set<string>());

  useEffect(() => {
    const displayed = displayedNotificationsRef.current;

    for (let { key, message, options = {}, closed = false } of notifications) {
      if (closed) {
        closeSnackbar(key);
        return;
      }
      if (displayed.has(key)) {
        return;
      }

      enqueueSnackbar(message, {
        ...options,
        key,
        onClose: (event, reason) => {
          if (options.onClose) {
            options.onClose(event, reason, key);
          }
        },
        onExited: () => {
          removeNotification(key);
          displayed.delete(key);
        },
      });

      displayed.add(key);
    }
  }, [notifications, removeNotification, enqueueSnackbar, closeSnackbar]);

  return <>{children}</>;
}

export default CustomSnackbarProvider;
