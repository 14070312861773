import { useEffect } from "react";
import DialogTitle from "@mui/material/DialogTitle";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import Stack from "@mui/material/Stack";
import DialogActions from "@mui/material/DialogActions";
import Button from "@mui/material/Button";

import ShortcutScope from "@obr/features/shortcuts/ShortcutScope";

import { useWhatsNewModalStore, useWhatsNewStore } from "./store";

import WhatsNew200 from "./releases/200/WhatsNew200";
import WhatsNew210 from "./releases/210/WhatsNew210";
import WhatsNew220 from "./releases/220/WhatsNew220";
import WhatsNew230 from "./releases/230/WhatsNew230";

function WhatsNewModal() {
  const open = useWhatsNewModalStore((state) => state.open);
  const close = useWhatsNewModalStore((state) => state.closeWhatsNew);
  const markSeen = useWhatsNewStore((state) => state.markSeen);

  useEffect(() => {
    if (open) {
      markSeen(APP_VERSION);
    }
  }, [open]);

  return (
    <>
      <Dialog open={open} onClose={close} fullWidth maxWidth="sm">
        <ShortcutScope scope="whats-new-modal">
          <DialogTitle>What's New</DialogTitle>
          <DialogContent sx={{ maxHeight: "800px" }}>
            <Stack gap={2}>
              <WhatsNew230 />
              <WhatsNew220 />
              <WhatsNew210 />
              <WhatsNew200 />
            </Stack>
          </DialogContent>
          <DialogActions sx={{ px: 2 }}>
            <Button fullWidth variant="outlined" onClick={close}>
              Done
            </Button>
          </DialogActions>
        </ShortcutScope>
      </Dialog>
    </>
  );
}

export default WhatsNewModal;
