import logo from "../monotone.png";

import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import Link from "@mui/material/Link";
import Container from "@mui/material/Container";
import Divider from "@mui/material/Divider";
import Stack from "@mui/material/Stack";

import { useLocation } from "react-router-dom";

import ThemeIconButton from "@obr/features/theme/ThemeIconButton";

function Footer() {
  const location = useLocation();

  if (
    location.pathname.startsWith("/room") ||
    location.pathname.startsWith("/embed")
  ) {
    return null;
  }

  return (
    <footer>
      <Container maxWidth="md" sx={{ mt: 2 }}>
        <Divider />
        <Toolbar sx={{ gap: 2, py: 2, alignItems: "center" }}>
          <a href="/">
            <Box sx={{ width: "24px", height: "24px" }}>
              <img src={logo} alt="monotone logo" />
            </Box>
          </a>
          <Stack direction="row" gap={2} flexWrap="wrap">
            <Link href="/" underline="hover" color="inherit" variant="caption">
              Home
            </Link>
            <Link
              href="https://docs.owlbear.rodeo"
              underline="hover"
              color="inherit"
              variant="caption"
              rel="noopener noreferrer"
              target="_blank"
            >
              Docs
            </Link>
            <Link
              href="https://blog.owlbear.rodeo"
              underline="hover"
              color="inherit"
              variant="caption"
              rel="noopener noreferrer"
              target="_blank"
            >
              Blog
            </Link>
            <Link
              href="https://extensions.owlbear.rodeo"
              underline="hover"
              color="inherit"
              variant="caption"
              rel="noopener noreferrer"
              target="_blank"
            >
              Extensions
            </Link>
            <Link
              href="/pricing"
              underline="hover"
              color="inherit"
              variant="caption"
            >
              Pricing
            </Link>
            <Link
              href="/about"
              underline="hover"
              color="inherit"
              variant="caption"
            >
              About
            </Link>
          </Stack>
          <Box flexGrow={1} />
          <Stack direction="row" gap={2} flexWrap="wrap">
            <Link
              href="/terms"
              underline="hover"
              color="inherit"
              variant="caption"
            >
              Terms
            </Link>
            <Link
              href="/privacy"
              underline="hover"
              color="inherit"
              variant="caption"
            >
              Privacy
            </Link>
          </Stack>
          <ThemeIconButton />
        </Toolbar>
      </Container>
    </footer>
  );
}

export default Footer;
