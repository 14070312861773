import Alert from "@mui/material/Alert";
import Typography from "@mui/material/Typography";

import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import Button from "@mui/material/Button";
import DialogActions from "@mui/material/DialogActions";
import Box from "@mui/material/Box";

import { Component, ErrorInfo, ReactNode } from "react";
import { captureException } from "./sentry";

interface Props {
  children?: ReactNode;
}

interface State {
  hasError: boolean;
  error?: Error;
  submitted?: boolean;
}

class ErrorBoundary extends Component<Props, State> {
  public state: State = {
    hasError: false,
  };

  public static getDerivedStateFromError(error: Error): State {
    // Update state so the next render will show the fallback UI.
    return { hasError: true, error };
  }

  public componentDidCatch(error: Error, errorInfo: ErrorInfo) {
    console.error("Uncaught error:", error, errorInfo);
  }

  public render() {
    if (this.state.hasError) {
      return (
        <Dialog open={true}>
          <DialogTitle>
            <Alert severity="error">Oops, something went wrong..</Alert>
          </DialogTitle>
          <DialogContent>
            <Box
              maxHeight="300px"
              bgcolor="background.paper"
              sx={{ overflowY: "auto", borderRadius: 1 }}
              p={2}
            >
              {this.state.error && (
                <Typography>
                  {this.state.error.name}: {this.state.error.message}
                </Typography>
              )}
              {this.state.error?.stack && (
                <Typography variant="caption">
                  {this.state.error.stack}
                </Typography>
              )}
            </Box>
          </DialogContent>
          <DialogActions sx={{ px: 2 }}>
            <Button
              fullWidth
              variant="outlined"
              onClick={() => {
                this.setState({ submitted: true });
                if (this.state.error) {
                  captureException(this.state.error);
                }
              }}
              disabled={this.state.submitted}
            >
              {this.state.submitted ? "Submitted" : "Submit Bug Report"}
            </Button>
          </DialogActions>
          <DialogActions sx={{ px: 2 }}>
            <Button
              fullWidth
              variant="contained"
              onClick={() => window.location.reload()}
            >
              Refresh Page
            </Button>
          </DialogActions>
        </Dialog>
      );
    }

    return this.props.children;
  }
}

export default ErrorBoundary;
