import { StateCreator } from "zustand";

export interface Scope {
  id: string;
  input?: boolean;
}

export interface ShortcutsSlice {
  /** Keep a stack of shortcut scopes to allow changing scopes when modals open */
  scopes: Scope[];
  addScope: (scope: string, input?: boolean) => string;
  removeScope: (scope: string) => string;
}

export const shortcutsSlice: StateCreator<ShortcutsSlice> = (set, get) => ({
  scopes: [],
  addScope: (scope, input?: boolean) => {
    set((state) => {
      state.scopes.push({ id: scope, input });
    });
    return getCurrentScope(get().scopes);
  },
  removeScope: (scope) => {
    set((state) => {
      const index = state.scopes.findIndex((s) => s.id === scope);
      if (index > -1) {
        state.scopes.splice(index, 1);
      }
    });
    return getCurrentScope(get().scopes);
  },
});

/**
 * Find the latest scope
 * If there is an input scope that will block all future non-input scopes
 */
export function getCurrentScope(scopes: Scope[]): string {
  let latest: Scope | undefined = undefined;
  for (const scope of scopes) {
    if (latest && latest.input && !scope.input) {
      continue;
    }
    latest = scope;
  }
  return latest?.id ?? "all";
}
