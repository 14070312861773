import Paper, { PaperProps } from "@mui/material/Paper";
import { forwardRef } from "react";

const TransparentPaper = forwardRef<HTMLDivElement, PaperProps>(
  (props, ref) => {
    return <Paper elevation={4} variant="transparent" {...props} ref={ref} />;
  }
);

export default TransparentPaper;
