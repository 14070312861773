import Avatar from "@mui/material/Avatar";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import Stack from "@mui/material/Stack";
import Divider from "@mui/material/Divider";
import Button from "@mui/material/Button";
import Link from "@mui/material/Link";

import PersonIcon from "@mui/icons-material/PersonRounded";

import PersonCheckIcon from "@obr/icons/PersonCheck";

import { useNotificationsStore } from "@obr/features/notifications/store";
import { useEffect, useState } from "react";
import { MediatedStorage } from "../embed/MediatedStorage";
import { useAuthStore } from "./store";

function StoragePromptForm({ onSignIn }: { onSignIn: () => void }) {
  const showNotification = useNotificationsStore(
    (state) => state.showNotification
  );

  // Close prompt automatically if signed in
  const role = useAuthStore((state) => state.role);
  useEffect(() => {
    if (role === "AUTHENTICATED") {
      onSignIn();
    }
  }, [role]);

  const [success, setSuccess] = useState(!MediatedStorage.usingFallback);

  async function handleRequestClick() {
    try {
      // Reason: We're using the StorageAccessHandle which is only supported in Chrome
      // https://developer.mozilla.org/en-US/docs/Web/API/StorageAccessHandle
      // @ts-ignore
      const handle = await document.requestStorageAccess({
        all: true,
      });

      // Reason: as above
      // @ts-ignore
      if (handle && handle.localStorage) {
        setSuccess(true);
      } else {
        showNotification(
          "Storage request failed. This browser does not support the required storage features",
          { variant: "error", persist: true }
        );
      }
    } catch (err) {
      console.error(err);
      showNotification(
        "Storage request failed. This browser does not support the required storage access",
        { variant: "error", persist: true }
      );
    }
  }

  return (
    <Container maxWidth="sm">
      <Stack width="100%" alignItems="center" gap={6}>
        <Stack alignItems="center" gap={2} width="100%" maxWidth={300}>
          <Avatar sx={{ m: 1, bgcolor: "secondary.main" }}>
            {success ? <PersonCheckIcon /> : <PersonIcon />}
          </Avatar>
          <Typography component="h1" variant="h5">
            {success ? "Access Granted" : "Access Needed"}
          </Typography>
          <Typography component="p" variant="caption">
            {success ? (
              <>
                Sign in using{" "}
                <Link
                  href={`${window.location.origin}/sign-in`}
                  rel="external nofollow noopener"
                  target="_blank"
                >
                  Owlbear Rodeo
                </Link>{" "}
                then reload this page.
              </>
            ) : (
              "In order to sign in storage access is needed"
            )}
          </Typography>
          {!success && (
            <>
              <Divider flexItem sx={{ mt: 1 }} />
              <Button
                fullWidth
                variant="contained"
                onClick={handleRequestClick}
              >
                Request Access
              </Button>
            </>
          )}
        </Stack>
      </Stack>
    </Container>
  );
}

export default StoragePromptForm;
