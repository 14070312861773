import useShortcutScope from "./useShortcutScope";

type ShortcutScopeProps = {
  scope: string;
  children?: React.ReactNode;
};

/** Helper component that wraps the children in a shortcut scope */
function ShortcutScope({ scope, children }: ShortcutScopeProps) {
  useShortcutScope(scope);

  return <>{children}</>;
}

export default ShortcutScope;
